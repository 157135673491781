import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { AuthProvider } from './auth/Auth';
import PrivateRoute from './components/PrivateRoute';
import axios from 'axios';

import './components/styles/style.css';

import Loading from './components/Loading/Loading';
const MainPage = lazy(() => import('./components/MainPages/index'));
const SetPassword = lazy(() => import('./components/SetPassword/SetPassword'));
const SignUp = lazy(() => import('./components/SignUp/SignUp'));
const Page404 = lazy(() => import('./components/Page404'));
const Login = lazy(() => import('./components/Login/Login'));

const NotFound = () => <Redirect to='/404' />;

const App = () => {
  axios.defaults.baseURL = 'https://europe-west3-order-lens.cloudfunctions.net';
  // axios.defaults.baseURL = 'http://localhost:5001/order-lens/europe-west3';

  return (
    <AuthProvider>
      <Router>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path='/login' component={Login} />
            <Route exact path='/signup' component={SignUp} />
            <Route exact path='/set-password' component={SetPassword} />
            <Route exact path='/404' component={Page404} />
            <PrivateRoute path='/' component={MainPage} />
            <Route path='*' component={NotFound} />
          </Switch>
        </Suspense>
      </Router>
    </AuthProvider>
  );
};

export default App;
