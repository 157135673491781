import {
  PUT_BRAND,
  PUT_POSITION,
  PUT_ORDER_LIST,
  PUT_CHANGE_ORDER_POSITION_QUANTITY,
  PUT_QTY_ISLOADING,
  PUT_SPH_ISLOADING,
  PUT_ORDER_POSITION_DELETE,
  PUT_QUANTITY,
  PUT_CLEAR_ORDER_LIST,
  PUT_GOODS_LIST,
  PUT_DATA_FOR_TABLE
} from '../actions/actionGoodsData';

const initialState = {
  brands: [],
  positions: [],
  goodsList: [],
  orderList: [],
  qtyIsLoading: false,
  sphIsLoading: false,
  dataForTable: []
};

export const dataGoods = (state = initialState, { type, payload }) => {
  switch (type) {
    case PUT_BRAND:
      return {
        ...state,
        brands: payload
      };
    case PUT_GOODS_LIST:
      return {
        ...state,
        goodsList: payload
      };
    case PUT_POSITION:
      return {
        ...state,
        positions: payload
      };
    case PUT_QTY_ISLOADING: {
      return {
        ...state,
        qtyIsLoading: payload
      };
    }
    case PUT_ORDER_LIST:
      return {
        ...state,
        orderList: [ ...state.orderList, payload ]
      };
    case PUT_CHANGE_ORDER_POSITION_QUANTITY:
      return {
        ...state,
        orderList: state.orderList.reduce((acc, itemState, indexState) => {
          if (indexState === payload.index) {
            acc.push({
              orderPosition: itemState.orderPosition,
              quantity: (itemState.quantity += payload.qty),
              id: itemState.id
            });
          } else {
            acc.push(itemState);
          }
          return acc;
        }, [])
      };
    case PUT_ORDER_POSITION_DELETE:
      let neworderList = [ ...state.orderList ];
      neworderList.splice(payload.index, 1);
      return {
        ...state,
        orderList: neworderList
      };
    case PUT_SPH_ISLOADING:
      return {
        ...state,
        sphIsLoading: payload
      };
    case PUT_QUANTITY:
      return {
        ...state,
        msgAvailable: payload
      };
    case PUT_CLEAR_ORDER_LIST:
      return {
        ...state,
        orderList: []
      };
    case PUT_DATA_FOR_TABLE:
      return {
        ...state,
        dataForTable: payload
      };
    default:
      return state;
  }
};
