import customAxiosPost from '../../utils/customAxiosPost';
import firebase from 'firebase/app';
import 'firebase/auth';
import { put, all, call, takeLatest, takeEvery } from 'redux-saga/effects';
import rsf from '../rsf';

import {
  LOAD_BRAND,
  LOAD_POSITION,
  LOAD_ORDER_LIST,
  putBrand,
  putPisition,
  putOrderList,
  putOrderPositionQuantity,
  LOAD_CHANGE_ORDER_POSITION_QUANTITY,
  putQtyIsLoading,
  LOAD_SPH_ISLOADING,
  putShpIsLoading,
  LOAD_ORDER_POSITION_DELETE,
  putOrderPositionDelete,
  LOAD_QTY_ISLOADING,
  LOAD_CLEAR_ORDER_LIST,
  putClearOrderList,
  putGoodsList,
  LOAD_GOODS_LIST,
  LOAD_DATA_FOR_TABLE,
  putDataForTable
} from '../actions/actionGoodsData';
import {
  putUserData,
  LOAD_USER_DATA,
  LOAD_CITIES,
  putCities,
  putAddressSelected,
  putFOPSelected,
  LOAD_ADDRESS_SELECTED,
  LOAD_FOP_SELECTED,
  LOAD_MANAGER_DATA,
  putManagerData,
  LOAD_DEBT,
  putDebt,
  putSubordinateData,
  LOAD_SUBORDINATE_DATA,
  putLoadingSubordinateData,
  LOAD_LOADING_SUBORDINATE_DATA
} from '../actions/actionUser';

import {
  LOAD_HIDE_MY_ORDER,
  LOAD_ORDERS,
  LOAD_ORDERS_ISLOADING,
  LOAD_RECIP_ORDERS,
  putHideMyOrder,
  putOrders,
  putOrdersIsLoading,
  putRecipOrdersIsLoading,
  putRecipOrders,
  LOAD_RECIP_ORDERS_ISLOADING
} from '../actions/actionOrders';
import { LOAD_ERRORS, putErrors } from '../actions/actionErrors';

// ERRORS  start

function* workerErrors({ payload }) {
  const data = yield payload;
  yield put(putErrors(data));
}

function* watchErrors() {
  yield takeEvery(LOAD_ERRORS, workerErrors);
}
// ERRORS  end

//BRAND List start

function* workerBrand({ payload }) {
  const data = yield payload;
  yield put(putBrand(data));
}

function* watchBrand() {
  yield takeLatest(LOAD_BRAND, workerBrand);
}
// Brand List end

//PositionList Start
function fetchPosition() {
  return customAxiosPost('/order/groupList').then((res) => res.data);
}
function* workerPosition() {
  try {
    const data = yield call(fetchPosition);
    yield put(putPisition(data));
  } catch (error) {
    yield put(putErrors({ status: true, er: error }));
  }
}
function* watchPosition() {
  yield takeLatest(LOAD_POSITION, workerPosition);
}
//PositionList End

//Order List start

function* workerOrderList({ payload }) {
  const data = yield payload;
  yield put(putOrderList(data));
}

function* watchOrderList() {
  yield takeLatest(LOAD_ORDER_LIST, workerOrderList);
}

//Order List end
//Order List Posotion Quantity satrt
function* workerOrderListQty({ payload }) {
  const data = yield payload;
  yield put(putOrderPositionQuantity(data));
}

function* watchOrderListQty() {
  yield takeLatest(LOAD_CHANGE_ORDER_POSITION_QUANTITY, workerOrderListQty);
}
//Order List Posotion Quantity end
//Order List Posotion Quantity satrt
function* workerOrderListDel({ payload }) {
  const data = yield payload;
  yield put(putOrderPositionDelete(data));
}

function* watchOrderListDel() {
  yield takeLatest(LOAD_ORDER_POSITION_DELETE, workerOrderListDel);
}
//Order List Posotion Quantity end

//USER DATA start

function* workerUser({ payload }) {
  const data = yield payload;
  yield put(putUserData(data));
}

function* watchUser() {
  yield takeLatest(LOAD_USER_DATA, workerUser);
}
// USER DATA end
//CITIES start

function* workerCities({ payload }) {
  const data = yield payload;
  yield put(putCities(data));
}

function* watchCities() {
  yield takeLatest(LOAD_CITIES, workerCities);
}
// CITIES end
//SPH IS LOADING start

function* workerSphIsLoading({ payload }) {
  const data = yield payload;
  yield put(putShpIsLoading(data));
}

function* watchSphIsLoading() {
  yield takeLatest(LOAD_SPH_ISLOADING, workerSphIsLoading);
}
// SPH IS LOADING end

//FOP SELECTED start
function* workerFOP({ payload }) {
  const data = yield payload;
  yield put(putFOPSelected(data));
}

function* watchFOP() {
  yield takeLatest(LOAD_FOP_SELECTED, workerFOP);
}
// FOP SELECTED end

//ADDRESS SELECTED start
function* workerAddress({ payload }) {
  const data = yield payload;
  yield put(putAddressSelected(data));
}

function* watchAddress() {
  yield takeLatest(LOAD_ADDRESS_SELECTED, workerAddress);
}
// ADDRESS SELECTED end

//QTY IS LOADIN start

function* workerQtyIsLoading({ payload }) {
  const data = yield payload;
  yield put(putQtyIsLoading(data));
}

function* watchQtyIsLoading() {
  yield takeEvery(LOAD_QTY_ISLOADING, workerQtyIsLoading);
}
// QTY IS LOADIN end

//Clear Order List start

function* workerClearOrderLis() {
  yield put(putClearOrderList());
}

function* watchClear() {
  yield takeLatest(LOAD_CLEAR_ORDER_LIST, workerClearOrderLis);
}
// Clear Order List end

//GOODS LIST Start
function fetchGoodsList(payload) {
  return customAxiosPost(`/order/goodsList`, {
    CodeCity: payload.CodeCity,
    CodeGroup: payload.CodeGroup,
    brand: payload.brand,
    Authorization: firebase.auth().currentUser.za
  }).then((res) => res.data);
}
function* workerGoodsList({ payload }) {
  try {
    yield put(putShpIsLoading(true));
    const data = yield call(fetchGoodsList, payload);
    yield put(putGoodsList(data));
  } catch (error) {
    yield put(putErrors({ status: true, er: error }));
  } finally {
    yield put(putShpIsLoading(false));
  }
}
function* watchGoodsList() {
  yield takeLatest(LOAD_GOODS_LIST, workerGoodsList);
}
//GOODS LIST End

//ORDERS ISLOADING from db start

function* workerOrdersIsLoading({ payload }) {
  const data = yield payload;
  yield put(putOrdersIsLoading(data));
}

function* watchOrdersIsLoading() {
  yield takeEvery(LOAD_ORDERS_ISLOADING, workerOrdersIsLoading);
}
// ORDERS ISLOADING from db end

function* workerRecipOrdersIsLoading({ payload }) {
  const data = yield payload;
  yield put(putRecipOrdersIsLoading(data));
}

function* watchRecipOrdersIsLoading() {
  yield takeEvery(LOAD_RECIP_ORDERS_ISLOADING, workerRecipOrdersIsLoading);
}
// ORDERS ISLOADING from db end

//ORDERS from db Start

function* workerOrders({ payload }) {
  try {
    yield put(putOrdersIsLoading(true));
    const data = yield payload;
    yield put(putOrders(data));
  } catch (error) {
    yield put(putErrors({ status: true, er: error }));
  } finally {
    yield put(putOrdersIsLoading(false));
  }
}
function* watchOrders() {
  yield takeLatest(LOAD_ORDERS, workerOrders);
}
//ORDERS from db End

//MANAGER LOAD start

function* workerManager({ payload }) {
  const data = yield payload;
  yield put(putManagerData(data));
}

function* watchManager() {
  yield takeLatest(LOAD_MANAGER_DATA, workerManager);
}
// DEBT LOAD end
function* workerDebt({ payload }) {
  const data = yield payload;
  yield put(putDebt(data));
}

function* watchDebt() {
  yield takeLatest(LOAD_DEBT, workerDebt);
}
// MDEBT LOAD end
// HIDE LOAD end
function* workerHideMyOrder({ payload }) {
  const data = yield payload;
  yield put(putHideMyOrder(data));
}

function* watchHideMyOrder() {
  yield takeLatest(LOAD_HIDE_MY_ORDER, workerHideMyOrder);
}
//ORDERS from db Start

function* workerRecipOrders({ payload }) {
  try {
    yield put(putRecipOrdersIsLoading(true));
    const data = yield payload;
    yield put(putRecipOrders(data));
  } catch (error) {
    yield put(putErrors({ status: true, er: error }));
  } finally {
    yield put(putRecipOrdersIsLoading(false));
  }
}
function* watchRecipOrders() {
  yield takeLatest(LOAD_RECIP_ORDERS, workerRecipOrders);
}
//ORDERS from db End

// SUBORDINATE DATE ISLOADING  start

function* workerSubordinateDataIsLoading({ payload }) {
  const data = yield payload;
  yield put(putLoadingSubordinateData(data));
}

function* watchSubordinateDataIsLoading() {
  yield takeEvery(LOAD_LOADING_SUBORDINATE_DATA, workerSubordinateDataIsLoading);
}
// SUBORDINATE DATE ISLOADING  end

//SUBORDINATE DATE Start
function fetchSubordinateData(payload) {
  return customAxiosPost(`/order/getUserBySupervisor`, {
    id: payload
  }).then((res) => res.data);
}
function* workerSubordinateData({ payload }) {
  try {
    yield put(putLoadingSubordinateData(true));
    const data = yield call(fetchSubordinateData, payload);
    yield put(putSubordinateData(data));
  } catch (error) {
    yield put(putErrors({ status: true, er: error }));
  } finally {
    yield put(putLoadingSubordinateData(false));
  }
}
function* watchSubordinateData() {
  yield takeLatest(LOAD_SUBORDINATE_DATA, workerSubordinateData);
}
//SUBORDINATE DATE End

// DATA FOR TABLE  start

function* workerDataForTable({ payload }) {
  try {
    const data = yield call(rsf.database.read, `dataForTableOrder/${payload}`);
    yield put(putDataForTable(data));
  } catch (error) {
    yield put(putErrors({ status: true, er: error }));
  }
}

function* watchDataForTable() {
  yield takeEvery(LOAD_DATA_FOR_TABLE, workerDataForTable);
}
// DATA FOR TABLE  end

export default function* rootSaga() {
  yield all([
    watchErrors(),
    watchUser(),
    watchBrand(),
    watchPosition(),
    watchOrderList(),
    watchOrderListQty(),
    watchCities(),
    watchSphIsLoading(),
    watchOrderListDel(),
    watchFOP(),
    watchAddress(),
    watchQtyIsLoading(),
    watchClear(),
    watchGoodsList(),
    watchOrdersIsLoading(),
    watchOrders(),
    watchManager(),
    watchDebt(),
    watchHideMyOrder(),
    watchRecipOrders(),
    watchRecipOrdersIsLoading(),
    watchSubordinateData(),
    watchSubordinateDataIsLoading(),
    watchDataForTable()
  ]);
}
