export const LOAD_BRAND = 'LOAD_BRAND';
export const PUT_BRAND = 'PUT_BRAND';
export const LOAD_POSITION = 'LOAD_POSITION';
export const PUT_POSITION = 'PUT_POSITION';
export const PUT_QTY_ISLOADING = 'PUT_QTY_ISLOADING';
export const LOAD_QTY_ISLOADING = 'LOAD_QTY_ISLOADING';
export const PUT_ORDER_LIST = 'PUT_ORDER_LIST';
export const LOAD_ORDER_LIST = 'LOAD_ORDER_LIST';
export const LOAD_CHANGE_ORDER_POSITION_QUANTITY = 'LOAD_CHANGE_ORDER_POSITION_QUANTITY';
export const PUT_CHANGE_ORDER_POSITION_QUANTITY = 'PUT_CHANGE_ORDER_POSITION_QUANTITY';
export const LOAD_QUANTITY = 'LOAD_QUANTITY';
export const PUT_QUANTITY = 'PUT_QUANTITY';
export const PUT_SPH_ISLOADING = 'PUT_SPH_ISLOADING';
export const LOAD_SPH_ISLOADING = 'LOAD_SPH_ISLOADING';
export const LOAD_ORDER_POSITION_DELETE = 'LOAD_ORDER_POSITION_DELETE';
export const PUT_ORDER_POSITION_DELETE = 'PUT_ORDER_POSITION_DELETE';
export const PUT_CLEAR_ORDER_LIST = 'PUT_CLEAR_ORDER_LIST';
export const LOAD_CLEAR_ORDER_LIST = 'LOAD_CLEAR_ORDER_LIST';
export const PUT_GOODS_LIST = 'PUT_GOODS_LIST';
export const LOAD_GOODS_LIST = 'LOAD_GOODS_LIST';
export const PUT_DATA_FOR_TABLE = 'PUT_DATA_FOR_TABLE';
export const LOAD_DATA_FOR_TABLE = 'LOAD_DATA_FOR_TABLE';

export const loadBrand = (state) => {
  return {
    type: LOAD_BRAND,
    payload: state
  };
};

export const putBrand = (brandFromFirebase) => {
  return {
    type: PUT_BRAND,
    payload: brandFromFirebase
  };
};
export const loadDataForTable = (state) => {
  return {
    type: LOAD_DATA_FOR_TABLE,
    payload: state
  };
};

export const putDataForTable = (data) => {
  return {
    type: PUT_DATA_FOR_TABLE,
    payload: data
  };
};

export const putPisition = (brandFromServer) => {
  return {
    type: PUT_POSITION,
    payload: brandFromServer
  };
};
export const putQtyIsLoading = (isLoading) => {
  return {
    type: PUT_QTY_ISLOADING,
    payload: isLoading
  };
};
export const loadQtyIsLoading = (state) => {
  return {
    type: LOAD_QTY_ISLOADING,
    payload: state
  };
};

export const loadPosition = () => {
  return {
    type: LOAD_POSITION
  };
};
export const loadClearOrderList = () => {
  return {
    type: LOAD_CLEAR_ORDER_LIST
  };
};
export const putClearOrderList = () => {
  return {
    type: PUT_CLEAR_ORDER_LIST
  };
};

export const loadOrderList = (state) => {
  return {
    type: LOAD_ORDER_LIST,
    payload: state
  };
};

export const putOrderList = (brandFromFirebase) => {
  return {
    type: PUT_ORDER_LIST,
    payload: brandFromFirebase
  };
};

export const loadOrderPositionQuantity = (state) => {
  return {
    type: LOAD_CHANGE_ORDER_POSITION_QUANTITY,
    payload: state
  };
};

export const putOrderPositionQuantity = (qtyAndIndex) => {
  return {
    type: PUT_CHANGE_ORDER_POSITION_QUANTITY,
    payload: qtyAndIndex
  };
};

export const loadSphIsLoading = (state) => {
  return {
    type: LOAD_SPH_ISLOADING,
    payload: state
  };
};

export const putShpIsLoading = (isLoading) => {
  return {
    type: PUT_SPH_ISLOADING,
    payload: isLoading
  };
};

export const loadOrderPositionDelete = (state) => {
  return {
    type: LOAD_ORDER_POSITION_DELETE,
    payload: state
  };
};

export const putOrderPositionDelete = (item) => {
  return {
    type: PUT_ORDER_POSITION_DELETE,
    payload: item
  };
};

export const loadQuantity = (state) => {
  return {
    type: LOAD_QUANTITY,
    payload: state
  };
};

export const putQuantity = (qty) => {
  return {
    type: PUT_QUANTITY,
    payload: qty
  };
};

export const putGoodsList = (goods) => {
  return {
    type: PUT_GOODS_LIST,
    payload: goods
  };
};
export const loadGoodsList = (state) => {
  return {
    type: LOAD_GOODS_LIST,
    payload: state
  };
};
