import {PUT_CITIES, PUT_USER_DATA, PUT_ADDRESS_SELECTED, PUT_FOP_SELECTED, PUT_MANAGER_DATA, PUT_DEBT, PUT_SUBORDINATE_DATA, PUT_LOADING_SUBORDINATE_DATA} from '../actions/actionUser'

const initialState = {
    user: [],
    cities: [],
    FOPSelected: '',
    addressSelected: '',
    managers: [],
    debet: [],
    subordinateOptions: {},
    subordinateOptionsIsLoading: false
}

export const userData = (state = initialState, {type, payload}) => {
    switch (type) {
        case PUT_USER_DATA:
            return {
            ...state,
            user: payload
        }
        case PUT_MANAGER_DATA:
            return {
            ...state,
            managers: payload
        }
        case PUT_CITIES:
            return {
                ...state,
                cities: payload
            }
        case PUT_FOP_SELECTED:
            return {
                ...state,
                FOPSelected: payload
            }
        case PUT_ADDRESS_SELECTED:
            return {
                ...state,
                addressSelected: payload
            }
        case PUT_SUBORDINATE_DATA:
            return {
                ...state,
                subordinateOptions: payload
            }
        case PUT_LOADING_SUBORDINATE_DATA:
            return {
                ...state,
                subordinateOptionsIsLoading: payload
            }
        case PUT_DEBT:
            return {
                ...state,
                debet: [...state.debet, payload]
            }
        default:
            return state
    }
}