import {PUT_HIDE_MY_ORDER, PUT_ORDERS, PUT_ORDERS_ISLOADING, PUT_RECIP_ORDERS, PUT_RECIP_ORDERS_ISLOADING} from '../actions/actionOrders'

const initialState = {
    ordersList: [],
    recipOrderList: [],
    ordersIsLoading: false,
    ordersRecipIsLoading: false,
    hideMyOrder: false
}

export const orders = (state = initialState, {type, payload}) => {
    switch (type) {
        case PUT_ORDERS:
            return {
                ...state,
                ordersList: payload
            }
        case PUT_ORDERS_ISLOADING:
            return{
                ...state,
                ordersIsLoading: payload
            }
        case PUT_RECIP_ORDERS_ISLOADING:
            return{
                ...state,
                ordersRecipIsLoading: payload
            }
        case PUT_HIDE_MY_ORDER:
            return{
                ...state,
                hideMyOrder: payload
            }
        case PUT_RECIP_ORDERS:
            return{
                ...state,
                recipOrderList: payload
            }
        default:
            return state
    }
} 