export const PUT_ORDERS_ISLOADING = 'PUT_ORDERS_ISLOADING'
export const LOAD_ORDERS_ISLOADING = 'LOAD_ORDERS_ISLOADING'
export const PUT_RECIP_ORDERS_ISLOADING = 'PUT_RECIP_ORDERS_ISLOADING'
export const LOAD_RECIP_ORDERS_ISLOADING = 'LOAD_RECIP_ORDERS_ISLOADING'
export const PUT_ORDERS = 'PUT_ORDERS'
export const LOAD_ORDERS = 'LOAD_ORDERS'
export const PUT_HIDE_MY_ORDER = 'PUT_HIDE_MY_ORDER'
export const LOAD_HIDE_MY_ORDER = 'LOAD_HIDE_MY_ORDER'
export const PUT_RECIP_ORDERS = 'PUT_RECIP_ORDERS'
export const LOAD_RECIP_ORDERS = 'LOAD_RECIP_ORDERS'

export const putOrders = (isLoading) => {
    return {
        type: PUT_ORDERS,
        payload: isLoading,
}
}
export const loadOrders = (state) => {
    return {
        type: LOAD_ORDERS,
        payload: state,
    }
}
export const putOrdersIsLoading = (isLoading) => {
    return {
        type: PUT_ORDERS_ISLOADING,
        payload: isLoading,
    }
}
export const loadOrdersIsLoading = (state) => {
    return {
        type: LOAD_ORDERS_ISLOADING,
        payload: state,
    }
}
export const putRecipOrdersIsLoading = (isLoading) => {
    return {
        type: PUT_RECIP_ORDERS_ISLOADING,
        payload: isLoading,
    }
}
export const loadRecipOrdersIsLoading = (state) => {
    return {
        type: LOAD_RECIP_ORDERS_ISLOADING,
        payload: state,
    }
}
export const putHideMyOrder = (isHide) => {
    return {
        type: PUT_HIDE_MY_ORDER,
        payload: isHide,
    }
}
export const loadHideMyOrder = (state) => {
    return {
        type: LOAD_HIDE_MY_ORDER,
        payload: state,
    }
}
export const putRecipOrders = (order) => {
    return {
        type: PUT_RECIP_ORDERS,
        payload: order,
    }
}
export const loadRecipOrders = (state) => {
    return {
        type: LOAD_RECIP_ORDERS,
        payload: state,
    }
}