export const LOAD_ERRORS = 'LOAD_ERRORS'
export const PUT_ERRORS = 'PUT_ERRORS'



export const loadErrors = (state) => {
    return {
        type: LOAD_ERRORS,
        payload: state
    }
  }
  
export const putErrors = (data) => {
  return{
      type: PUT_ERRORS,
      payload: data
  }
}