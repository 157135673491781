import { PUT_ERRORS } from '../actions/actionErrors'

const initialState = {
  error: {status: false, er: {}}
}

export const errorData = (state = initialState, {type, payload}) => {
    switch (type) {
        case PUT_ERRORS:
            return {
            ...state,
            error: payload
        }
        default:
            return state
    }
}