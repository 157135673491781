import { createStore, applyMiddleware, combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'

import { dataGoods } from './reducer/goodsReducer'
import { userData } from './reducer/userReducer'
import { orders } from './reducer/ordersRebucer'
import { errorData } from './reducer/errorsReducer'
import rootSaga from './sagas/saga'

const allReducers = combineReducers({
  dataGoods,
  userData,
  orders,
  errorData
})

const sagaMiddleware = createSagaMiddleware()
export const store = createStore(
  allReducers,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(rootSaga)
