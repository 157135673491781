export const LOAD_USER_DATA = 'LOAD_USER_DATA'
export const PUT_USER_DATA = 'PUT_USER_DATA'
export const LOAD_CITIES = 'LOAD_CITIES'
export const PUT_CITIES = 'PUT_CITIES'
export const PUT_FOP_SELECTED = 'PUT_FOP_SELECTED'
export const LOAD_FOP_SELECTED = 'LOAD_FOP_SELECTED'
export const PUT_ADDRESS_SELECTED = 'PUT_ADDRESS_SELECTED'
export const LOAD_ADDRESS_SELECTED = 'LOAD_ADDRESS_SELECTED'
export const PUT_MANAGER_DATA = 'PUT_MANAGER_DATA'
export const LOAD_MANAGER_DATA = 'LOAD_MANAGER_DATA'
export const PUT_DEBT = 'PUT_DEBT'
export const LOAD_DEBT = 'LOAD_DEBT'
// export const PUT_UPDATE = 'PUT_UPDATE'
// export const LOAD_UPDATE = 'LOAD_UPDATE'
export const PUT_SUBORDINATE_DATA = 'PUT_SUBORDINATE_DATA'
export const LOAD_SUBORDINATE_DATA = 'LOAD_SUBORDINATE_DATA'
export const PUT_LOADING_SUBORDINATE_DATA = 'PUT_LOADING_SUBORDINATE_DATA'
export const LOAD_LOADING_SUBORDINATE_DATA = 'LOAD_LOADING_SUBORDINATE_DATA'


export const loadUserData = (state) => {
    return {
        type: LOAD_USER_DATA,
        payload: state
    }
  }
  
export const putUserData = (userFromFirebase) => {
  return{
      type: PUT_USER_DATA,
      payload: userFromFirebase
  }
}

export const loadCities = (state) => {
    return {
        type: LOAD_CITIES,
        payload: state
    }
  }
  
export const putCities = (citiesFromFirebase) => {
  return{
      type: PUT_CITIES,
      payload: citiesFromFirebase
  }
}

export const loadFOPSelected = (state) => {
  return {
      type: LOAD_FOP_SELECTED,
      payload: state
  }
}

export const putFOPSelected = (FOP) => {
  return{
      type: PUT_FOP_SELECTED,
      payload: FOP
  }
}
export const putAddressSelected = (address) => {
  return{
      type: PUT_ADDRESS_SELECTED,
      payload: address
  }
}
export const loadAddressSelected = (state) => {
  return {
      type: LOAD_ADDRESS_SELECTED,
      payload: state
  }
}

export const loadManagerData = (state) => {
  return {
      type: LOAD_MANAGER_DATA,
      payload: state
  }
}

export const putManagerData = (managerFromFirebase) => {
  return{
      type: PUT_MANAGER_DATA,
      payload: managerFromFirebase
  }
}
export const loadDebt = (state) => {
  return {
      type: LOAD_DEBT,
      payload: state
  }
}

export const putDebt = (debt) => {
  return{
      type: PUT_DEBT,
      payload: debt
  }
}
export const loadSubordinateData = (state) => {
  return {
      type: LOAD_SUBORDINATE_DATA,
      payload: state
  }
}

export const putSubordinateData = (data) => {
  return{
      type: PUT_SUBORDINATE_DATA,
      payload: data
  }
}
export const loadLoadingSubordinateData = (state) => {
  return {
      type: LOAD_LOADING_SUBORDINATE_DATA,
      payload: state
  }
}

export const putLoadingSubordinateData = (bool) => {
  return{
      type: PUT_LOADING_SUBORDINATE_DATA,
      payload: bool
  }
}